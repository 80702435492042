<template>
    <div class="result_row">
        <div class="row_title">
            {{ $t('search.events') }}
        </div>
        <div class="links_articles flex">
            <div
                v-for="(event, index) in events?.rows"
                :key="index"
                class="link_row"
            >
                <component
                    :is="event.externalLink ? 'a' : 'nuxt-link'"
                    v-bind="getLinkAttributes(event)"
                    class="link"
                >
                    <div class="title">
                        {{ event.name }}
                    </div>
                </component>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        events: {
            type: Object,
            required: true
        }
    },

    methods: {
        getLinkAttributes(event) {
            const { article, externalLink } = event;

            if (externalLink) {
                return {
                    href: externalLink,
                    target: '_blank',
                    rel: 'noopener noreferrer'
                };
            }

            if (article) {
                return {
                    to: this.$getArticleLink(article)
                };
            }

            return { to: this.localePath('/') };
        }
    }
};
</script>
