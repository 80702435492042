var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('newsletter'),_vm._v(" "),_c('footer',{staticClass:"b_footer"},[_c('div',{staticClass:"footer_top"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"top_wrapper row flex justify_between"},[_c('footer-menu',{attrs:{"menu-items":_vm.content.menuItems}}),_vm._v(" "),_c('div',{staticClass:"contribution text_center"},[_c('div',{staticClass:"block_label"},[_vm._v("\n                            "+_vm._s(_vm.$t('footer.contribution'))+"\n                        ")]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('p',[_vm._v("\n                                "+_vm._s(_vm.$t('footer.withYourContribution'))+"\n                            ")])]),_vm._v(" "),_c('a',{staticClass:"btn btn_red btn_shadow btn_donation",attrs:{"href":"https://donate.cancer.org.il/?utm_source=landing_page&utm_medium=cancer_org_il_btn_header","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("\n                            "+_vm._s(_vm.$t('footer.forDonation'))+"\n                        ")]),_vm._v(" "),_c('div',{staticClass:"award_wrapper"},[_c('div',{staticClass:"award flex justify_center align_center"},[_c('div',{staticClass:"award_image"},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                            'award_01.png'
                                        ),expression:"\n                                            'award_01.png'\n                                        ",arg:"assets"}]})]),_vm._v(" "),_c('div',{staticClass:"award_image"},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                            'award_02.png'
                                        ),expression:"\n                                            'award_02.png'\n                                        ",arg:"assets"}]})])])])])],1)])]),_vm._v(" "),_c('div',{staticClass:"footer_bottom"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"bottom_wrapper flex row"},[_c('div',{staticClass:"right_content"},[_c('div',{staticClass:"content"},[_c('i18n',{attrs:{"path":"footer.longText.content","tag":"p"},scopedSlots:_vm._u([{key:"terms",fn:function(){return [_c('nuxt-link',{attrs:{"to":_vm.localePath('/terms')}},[_vm._v("\n                                        "+_vm._s(_vm.$t('footer.longText.terms'))+"\n                                    ")])]},proxy:true},{key:"privacy",fn:function(){return [_c('nuxt-link',{attrs:{"to":_vm.localePath('/privacy-policy')}},[_vm._v("\n                                        "+_vm._s(_vm.$t('footer.longText.privacy'))+"\n                                    ")])]},proxy:true}])})],1),_vm._v(" "),_c('div',{staticClass:"info flex align_center"},[_c('div',{staticClass:"copy_wrapper"},[_c('div',{staticClass:"copy"},[_vm._v("\n                                    "+_vm._s(_vm.$t('footer.allRightsReserved'))+"\n                                ")])]),_vm._v(" "),_c('span',[_vm._v("|")]),_vm._v(" "),_c('div',{staticClass:"powered flex align_center"},[_c('span',[_vm._v("Powered By")]),_vm._v(" "),_c('a',{attrs:{"href":"https://www.prpl.io/?ref=cancer.org.il","target":"_blank","rel":"noopener noreferrer"}},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                            'logo_purple.svg'
                                        ),expression:"\n                                            'logo_purple.svg'\n                                        ",arg:"assets"}]})])])])]),_vm._v(" "),_c('div',{staticClass:"left_content"},[_c('div',{staticClass:"title second_font"},[_c('h3',[_vm._v(_vm._s(_vm.$t('footer.fightCancer')))])]),_vm._v(" "),_c('div',{staticClass:"bottom_menu flex justify_center"},[_c('div',{staticClass:"menu_link block"},[_c('div',{staticClass:"icon"},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                            'icon_research.svg'
                                        ),expression:"\n                                            'icon_research.svg'\n                                        ",arg:"assets"}]})]),_vm._v(" "),_c('div',{staticClass:"link_title"},[_vm._v("\n                                    "+_vm._s(_vm.$t('footer.research'))+"\n                                ")])]),_vm._v(" "),_c('div',{staticClass:"menu_link block"},[_c('div',{staticClass:"icon"},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                            'icon_prevention_diagnosis.svg'
                                        ),expression:"\n                                            'icon_prevention_diagnosis.svg'\n                                        ",arg:"assets"}]})]),_vm._v(" "),_c('div',{staticClass:"link_title"},[_vm._v("\n                                    "+_vm._s(_vm.$t('footer.preventionAndDiagnosis'))+"\n                                ")])]),_vm._v(" "),_c('div',{staticClass:"menu_link block"},[_c('div',{staticClass:"icon"},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                            'icon_rehabilitation.svg'
                                        ),expression:"\n                                            'icon_rehabilitation.svg'\n                                        ",arg:"assets"}]})]),_vm._v(" "),_c('div',{staticClass:"link_title"},[_vm._v("\n                                    "+_vm._s(_vm.$t('footer.rehabilitation'))+"\n                                ")])]),_vm._v(" "),_c('div',{staticClass:"menu_link block"},[_c('div',{staticClass:"icon"},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                            'icon_professional_information.svg'
                                        ),expression:"\n                                            'icon_professional_information.svg'\n                                        ",arg:"assets"}]})]),_vm._v(" "),_c('div',{staticClass:"link_title"},[_vm._v("\n                                    "+_vm._s(_vm.$t('footer.professionalInformation'))+"\n                                ")])])])])])])]),_vm._v(" "),_c('div',{staticClass:"copy-mobile"},[_c('div',{staticClass:"info flex align_center"},[_c('div',{staticClass:"powered flex align_center"},[_c('span',[_vm._v("Powered By")]),_vm._v(" "),_c('a',{attrs:{"href":"https://www.prpl.io/?ref=cancer.org.il","target":"_blank","rel":"noopener noreferrer"}},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:('purple_light.svg'),expression:"'purple_light.svg'",arg:"assets"}],staticClass:"copy-mobile-logo"})])]),_vm._v(" "),_c('div',{staticClass:"copy_wrapper"},[_c('div',{staticClass:"copy"},[_vm._v("\n                        "+_vm._s(_vm.$t('footer.allRightsReserved'))+"\n                    ")])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }