<template>
    <div class="result_row">
        <div class="row_title">
            {{ $t('search.pages') }}
        </div>
        <div class="links_wrapper flex">
            <div
                v-for="(page, index) in pages?.rows"
                :key="index"
                class="link_row"
            >
                <nuxt-link :to="localePath(page.url)" class="link">
                    <div class="title">
                        {{ $t(`search.pageNames.${getKey(page.key)}`) }}
                    </div>
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pages: {
            type: Object,
            required: true
        }
    },

    methods: {
        getKey(key) {
            return key.split('-')[0];
        }
    }
};
</script>
