<template>
    <div class="result_row">
        <div class="row_title">
            {{ $t('search.branches') }}
        </div>
        <div class="links_wrapper flex">
            <div
                v-for="(branch, index) in branches?.rows"
                :key="index"
                class="link_row"
            >
                <nuxt-link
                    :to="
                        localePath(
                            `/branches/regions/${branch.region.slug}/${branch.slug}`
                        )
                    "
                    class="link"
                >
                    <div class="title">
                        {{ branch.title }}
                    </div>
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        branches: {
            type: Object,
            required: true
        }
    }
};
</script>
