import { StatusCodes, CodesToMessage } from '@/enums/redirects';

export default function ({ error, route, redirect, store }) {
    const redirects = store.state.redirects;

    const match = redirects.find(r => r.from === route.path);

    if (match) {
        const { statusCode } = match;
        const statusCodesRedirectToErrorPage = Object.keys(CodesToMessage);

        if (statusCodesRedirectToErrorPage.includes(match.statusCode)) {
            return error({
                statusCode,
                message: CodesToMessage[statusCode]
            });
        }

        return redirect(statusCode || StatusCodes.FOUND, match.to);
    }
}
