<template>
    <div
        v-click-outside="close"
        class="lang_menu drop_menu js-drop-menu"
        :class="{ active: isSelectLanguageOpened }"
    >
        <div class="menu">
            <ul>
                <li v-for="locale in filteredLocales" :key="locale.code">
                    <a
                        v-if="langCodeToOldPage[locale.code]"
                        :href="langCodeToOldPage[locale.code]"
                    >
                        {{ locale.name }}
                    </a>

                    <a v-else @click.prevent="switchLang(locale.code)">
                        {{ locale.name }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';

import langCodeToOldPage from '@/helpers/langCodeToOldPage';

export default {
    computed: {
        ...mapGetters(['locale', 'isSelectLanguageOpened']),

        filteredLocales() {
            return this.$i18n.locales.filter(item => item.code !== this.locale);
        },

        langCodeToOldPage() {
            return langCodeToOldPage;
        }
    },

    methods: {
        ...mapMutations({
            setIsSelectLanguageOpened: 'SET_IS_SELECT_LANGUAGE_OPENED'
        }),

        ...mapActions({
            setLocale: 'setLocale'
        }),

        switchLang(code) {
            this.setIsSelectLanguageOpened(false);

            this.setLocale(code);

            this.$router.push(this.switchLocalePath(code));
        },

        close() {
            this.setIsSelectLanguageOpened(false);
        }
    }
};
</script>
