<template>
    <div class="search_result_wrapper">
        <div class="search_result">
            <div v-if="noResultsFlag" class="search_no_results_info">
                {{ $t('search.noResultsFound') }}
            </div>

            <categories-section
                v-if="categories?.rows?.length"
                :categories="categories"
            />

            <articles-section
                v-if="articles?.rows?.length"
                :articles="articles"
            />

            <pages-section v-if="pages?.rows?.length" :pages="pages" />

            <medication-categories-section
                v-if="medicationCategories?.rows?.length"
                :medication-categories="medicationCategories"
            />

            <clinical-research-categories-section
                v-if="clinicalResearchCategories?.rows?.length"
                :clinical-research-categories="clinicalResearchCategories"
            />

            <forums-section v-if="forums?.rows?.length" :forums="forums" />

            <events-section v-if="events?.rows?.length" :events="events" />

            <branches-section
                v-if="branches?.rows?.length"
                :branches="branches"
            />

            <newsflashes-section
                v-if="newsflashes?.rows?.length"
                :newsflashes="newsflashes"
            />
        </div>
    </div>
</template>

<script>
import PagesSection from '@/components/layout/header-items/search-desktop/PagesSection';
import EventsSection from '@/components/layout/header-items/search-desktop/EventsSection';
import ForumsSection from '@/components/layout/header-items/search-desktop/ForumsSection';
import ArticlesSection from '@/components/layout/header-items/search-desktop/ArticlesSection';
import BranchesSection from '@/components/layout/header-items/search-desktop/BranchesSection';
import CategoriesSection from '@/components/layout/header-items/search-desktop/CategoriesSection';
import NewsflashesSection from '@/components/layout/header-items/search-desktop/NewsflashesSection';
import MedicationCategoriesSection from '@/components/layout/header-items/search-desktop/MedicationCategoriesSection';
import ClinicalResearchCategoriesSection from '@/components/layout/header-items/search-desktop/ClinicalResearchCategoriesSection';

export default {
    components: {
        PagesSection,
        EventsSection,
        ForumsSection,
        ArticlesSection,
        BranchesSection,
        CategoriesSection,
        NewsflashesSection,
        MedicationCategoriesSection,
        ClinicalResearchCategoriesSection
    },

    props: {
        results: {
            type: Object,
            required: true
        }
    },

    computed: {
        articles() {
            return this.results?.articles || {};
        },

        categories() {
            return this.results?.categories || {};
        },

        pages() {
            return this.results?.pages || {};
        },

        forums() {
            return this.results?.forums || {};
        },

        events() {
            return this.results?.events || {};
        },

        branches() {
            return this.results?.branches || {};
        },

        newsflashes() {
            return this.results?.newsflashes || {};
        },

        medicationCategories() {
            return this.results?.medicationCategories || {};
        },

        clinicalResearchCategories() {
            return this.results?.clinicalResearchCategories || {};
        },

        noResultsFlag() {
            return !(
                this.articles?.rows?.length ||
                this.categories?.rows?.length ||
                this.pages?.rows?.length ||
                this.forums?.rows?.length ||
                this.events?.rows?.length ||
                this.branches?.rows?.length ||
                this.newsflashes?.rows?.length ||
                this.medicationCategories?.rows?.length ||
                this.clinicalResearchCategories?.rows?.length
            );
        }
    }
};
</script>
