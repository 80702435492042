var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isHidden)?_c('div',{staticClass:"b_articles section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section_title"},[_c('h3',[_vm._v("\n                "+_vm._s(_vm.content.title || _vm.content.moreInformation?.title)+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"article_wrapper"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"article_card"},[_c('div',{staticClass:"article_row"},[_c('article',{staticClass:"card_block"},[_c(_vm.isExternalLink(item) ? 'a' : 'nuxt-link',_vm._b({tag:"component",staticClass:"card_head"},'component',_vm.getLinkAttributes(item),false),[_c('div',{staticClass:"article_image"},[(
                                        _vm.checkIfProperPictureAttached(
                                            item.information?.featuredImage
                                        )
                                    )?_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path.original",value:(
                                        item.information.featuredImage
                                    ),expression:"\n                                        item.information.featuredImage\n                                    ",modifiers:{"original":true}}]}):_c('div',{staticClass:"no_image"},[_c('picture',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                            'article_image_no_image.svg'
                                        ),expression:"\n                                            'article_image_no_image.svg'\n                                        ",arg:"assets"}]})])]),_vm._v(" "),(item.information)?_c('div',{staticClass:"article_description"},[_c('h3',[_vm._v(_vm._s(item.information.text))]),_vm._v(" "),(item.information.description)?_c('p',[_vm._v("\n                                    "+_vm._s(item.information.description)+"\n                                ")]):_vm._e()]):(item.text)?_c('div',{staticClass:"article_description"},[_c('h3',[_vm._v(_vm._s(item.text))]),_vm._v(" "),(item.description)?_c('p',[_vm._v("\n                                    "+_vm._s(item.description)+"\n                                ")]):_vm._e()]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"more_wrap self_end"},[_c(_vm.isExternalLink(item) ? 'a' : 'nuxt-link',_vm._b({tag:"component",staticClass:"link_arrow"},'component',_vm.getLinkAttributes(item),false),[_vm._v("\n                                "+_vm._s(_vm.$t('homepage.buttonsSection.learnMore'))+"\n                            ")])],1)],1)])])}),0)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }