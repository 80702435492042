export const StatusCodes = {
    MOVED_PERMANENTLY: '301',
    FOUND: '302',
    PERMANENT_REDIRECT: '308',
    NOT_FOUND: '404',
    GONE: '410'
};

export const CodesToMessage = {
    404: 'Not Found',
    410: 'Gone'
};
