<template>
    <div class="mobile_search_result">
        <div class="search_result">
            <ul>
                <li v-if="noResultsFlag" class="search_no_results_info">
                    {{ $t('search.noResultsFound') }}
                </li>

                <li v-if="categoriesRows.length">
                    <b>{{ $t('search.categories') }}</b>
                </li>
                <li
                    v-for="(category, index) in categoriesRows"
                    :key="`header-search-category-${index}`"
                >
                    <nuxt-link :to="getCategoryUrl(category)">
                        {{ category.name }}
                    </nuxt-link>
                </li>

                <li v-if="articlesRows.length">
                    <b>{{ $t('search.articles') }}</b>
                </li>
                <li
                    v-for="(article, index) in articlesRows"
                    :key="`header-search-article-${index}`"
                >
                    <nuxt-link :to="$getArticleLink(article)">
                        {{ article.name }}
                    </nuxt-link>
                </li>

                <li v-if="forumsRows.length">
                    <b>{{ $t('search.forums') }}</b>
                </li>
                <li
                    v-for="(forum, index) in forumsRows"
                    :key="`header-search-forum-${index}`"
                >
                    <nuxt-link :to="localePath(`/forum/${forum.slug}`)">
                        {{ forum.name }}
                    </nuxt-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        results: {
            type: Object,
            required: true
        }
    },

    computed: {
        categoriesRows() {
            return this.results?.categories?.rows || [];
        },

        articlesRows() {
            return this.results?.articles?.rows || [];
        },

        forumsRows() {
            return this.results?.forums?.rows || [];
        },

        noResultsFlag() {
            return !(
                this.categoriesRows?.length ||
                this.articlesRows?.length ||
                this.forumsRows?.length
            );
        }
    },

    methods: {
        getCategoryUrl(category) {
            const page = category.parentCategoryId
                ? 'subcategories'
                : 'categories';

            return this.localePath(`/${page}/${category.slug}`);
        }
    }
};
</script>
