<template>
    <div class="result_row">
        <div class="row_title">
            {{ $t('search.drugs') }}
        </div>
        <div class="links_wrapper flex">
            <div
                v-for="(
                    medicationCategory, index
                ) in medicationCategories?.rows"
                :key="index"
                class="link_row"
            >
                <nuxt-link
                    :to="localePath(`/drugs/${medicationCategory.slug}`)"
                    class="link"
                >
                    <div class="title">
                        {{ medicationCategory.name }}
                    </div>
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        medicationCategories: {
            type: Object,
            required: true
        }
    }
};
</script>
