import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _570a3874 = () => interopDefault(import('../../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2c17bc6f = () => interopDefault(import('../../pages/branch-activities/index.vue' /* webpackChunkName: "pages/branch-activities/index" */))
const _29c53b0f = () => interopDefault(import('../../pages/branches/index.vue' /* webpackChunkName: "pages/branches/index" */))
const _0a6b475b = () => interopDefault(import('../../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _1e60f93a = () => interopDefault(import('../../pages/clinical-researches/index.vue' /* webpackChunkName: "pages/clinical-researches/index" */))
const _56ee0897 = () => interopDefault(import('../../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _fcf9e962 = () => interopDefault(import('../../pages/contribution/index.vue' /* webpackChunkName: "pages/contribution/index" */))
const _0d9f66ec = () => interopDefault(import('../../pages/drugs/index.vue' /* webpackChunkName: "pages/drugs/index" */))
const _1d1787d0 = () => interopDefault(import('../../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _2a1f8b58 = () => interopDefault(import('../../pages/forum/index.vue' /* webpackChunkName: "pages/forum/index" */))
const _3266b1bc = () => interopDefault(import('../../pages/newsflashes/index.vue' /* webpackChunkName: "pages/newsflashes/index" */))
const _1c4e8f5a = () => interopDefault(import('../../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _5db481c1 = () => interopDefault(import('../../pages/rights-and-services/index.vue' /* webpackChunkName: "pages/rights-and-services/index" */))
const _cc5ccc32 = () => interopDefault(import('../../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _154757ce = () => interopDefault(import('../../pages/speaker-messages/index.vue' /* webpackChunkName: "pages/speaker-messages/index" */))
const _4a4e9d27 = () => interopDefault(import('../../pages/strong-together/index.vue' /* webpackChunkName: "pages/strong-together/index" */))
const _7e78a1fb = () => interopDefault(import('../../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _7eca8b28 = () => interopDefault(import('../../pages/volunteers/index.vue' /* webpackChunkName: "pages/volunteers/index" */))
const _28325e10 = () => interopDefault(import('../../pages/strong-together/contact/index.vue' /* webpackChunkName: "pages/strong-together/contact/index" */))
const _ae4da40e = () => interopDefault(import('../../pages/volunteers/contact/index.vue' /* webpackChunkName: "pages/volunteers/contact/index" */))
const _55e02bd6 = () => interopDefault(import('../../pages/volunteers/thank-you/index.vue' /* webpackChunkName: "pages/volunteers/thank-you/index" */))
const _05841362 = () => interopDefault(import('../../pages/clinical-researches/categories/_slug/index.vue' /* webpackChunkName: "pages/clinical-researches/categories/_slug/index" */))
const _c8157198 = () => interopDefault(import('../../pages/branches/regions/_slug/_branchSlug.vue' /* webpackChunkName: "pages/branches/regions/_slug/_branchSlug" */))
const _1a1335f4 = () => interopDefault(import('../../pages/articles/_slug.vue' /* webpackChunkName: "pages/articles/_slug" */))
const _05f9db54 = () => interopDefault(import('../../pages/categories/_slug/index.vue' /* webpackChunkName: "pages/categories/_slug/index" */))
const _110ca97c = () => interopDefault(import('../../pages/drugs/_slug.vue' /* webpackChunkName: "pages/drugs/_slug" */))
const _0ec1a493 = () => interopDefault(import('../../pages/forum/_slug/index.vue' /* webpackChunkName: "pages/forum/_slug/index" */))
const _77d793c0 = () => interopDefault(import('../../pages/newsletter/_id.vue' /* webpackChunkName: "pages/newsletter/_id" */))
const _65d959ce = () => interopDefault(import('../../pages/subcategories/_slug/index.vue' /* webpackChunkName: "pages/subcategories/_slug/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/ar/",
    component: _570a3874,
    pathToRegexpOptions: {"strict":true},
    name: "index___ar"
  }, {
    path: "/branch-activities/",
    component: _2c17bc6f,
    pathToRegexpOptions: {"strict":true},
    name: "branch-activities___he"
  }, {
    path: "/branches/",
    component: _29c53b0f,
    pathToRegexpOptions: {"strict":true},
    name: "branches___he"
  }, {
    path: "/categories/",
    component: _0a6b475b,
    pathToRegexpOptions: {"strict":true},
    name: "categories___he"
  }, {
    path: "/clinical-researches/",
    component: _1e60f93a,
    pathToRegexpOptions: {"strict":true},
    name: "clinical-researches___he"
  }, {
    path: "/contact/",
    component: _56ee0897,
    pathToRegexpOptions: {"strict":true},
    name: "contact___he"
  }, {
    path: "/contribution/",
    component: _fcf9e962,
    pathToRegexpOptions: {"strict":true},
    name: "contribution___he"
  }, {
    path: "/drugs/",
    component: _0d9f66ec,
    pathToRegexpOptions: {"strict":true},
    name: "drugs___he"
  }, {
    path: "/en/",
    component: _570a3874,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/events/",
    component: _1d1787d0,
    pathToRegexpOptions: {"strict":true},
    name: "events___he"
  }, {
    path: "/forum/",
    component: _2a1f8b58,
    pathToRegexpOptions: {"strict":true},
    name: "forum___he"
  }, {
    path: "/newsflashes/",
    component: _3266b1bc,
    pathToRegexpOptions: {"strict":true},
    name: "newsflashes___he"
  }, {
    path: "/privacy-policy/",
    component: _1c4e8f5a,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___he"
  }, {
    path: "/rights-and-services/",
    component: _5db481c1,
    pathToRegexpOptions: {"strict":true},
    name: "rights-and-services___he"
  }, {
    path: "/ru/",
    component: _570a3874,
    pathToRegexpOptions: {"strict":true},
    name: "index___ru"
  }, {
    path: "/search/",
    component: _cc5ccc32,
    pathToRegexpOptions: {"strict":true},
    name: "search___he"
  }, {
    path: "/speaker-messages/",
    component: _154757ce,
    pathToRegexpOptions: {"strict":true},
    name: "speaker-messages___he"
  }, {
    path: "/strong-together/",
    component: _4a4e9d27,
    pathToRegexpOptions: {"strict":true},
    name: "strong-together___he"
  }, {
    path: "/terms/",
    component: _7e78a1fb,
    pathToRegexpOptions: {"strict":true},
    name: "terms___he"
  }, {
    path: "/volunteers/",
    component: _7eca8b28,
    pathToRegexpOptions: {"strict":true},
    name: "volunteers___he"
  }, {
    path: "/ar/branch-activities/",
    component: _2c17bc6f,
    pathToRegexpOptions: {"strict":true},
    name: "branch-activities___ar"
  }, {
    path: "/ar/branches/",
    component: _29c53b0f,
    pathToRegexpOptions: {"strict":true},
    name: "branches___ar"
  }, {
    path: "/ar/categories/",
    component: _0a6b475b,
    pathToRegexpOptions: {"strict":true},
    name: "categories___ar"
  }, {
    path: "/ar/clinical-researches/",
    component: _1e60f93a,
    pathToRegexpOptions: {"strict":true},
    name: "clinical-researches___ar"
  }, {
    path: "/ar/contact/",
    component: _56ee0897,
    pathToRegexpOptions: {"strict":true},
    name: "contact___ar"
  }, {
    path: "/ar/contribution/",
    component: _fcf9e962,
    pathToRegexpOptions: {"strict":true},
    name: "contribution___ar"
  }, {
    path: "/ar/drugs/",
    component: _0d9f66ec,
    pathToRegexpOptions: {"strict":true},
    name: "drugs___ar"
  }, {
    path: "/ar/events/",
    component: _1d1787d0,
    pathToRegexpOptions: {"strict":true},
    name: "events___ar"
  }, {
    path: "/ar/forum/",
    component: _2a1f8b58,
    pathToRegexpOptions: {"strict":true},
    name: "forum___ar"
  }, {
    path: "/ar/newsflashes/",
    component: _3266b1bc,
    pathToRegexpOptions: {"strict":true},
    name: "newsflashes___ar"
  }, {
    path: "/ar/privacy-policy/",
    component: _1c4e8f5a,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___ar"
  }, {
    path: "/ar/rights-and-services/",
    component: _5db481c1,
    pathToRegexpOptions: {"strict":true},
    name: "rights-and-services___ar"
  }, {
    path: "/ar/search/",
    component: _cc5ccc32,
    pathToRegexpOptions: {"strict":true},
    name: "search___ar"
  }, {
    path: "/ar/speaker-messages/",
    component: _154757ce,
    pathToRegexpOptions: {"strict":true},
    name: "speaker-messages___ar"
  }, {
    path: "/ar/strong-together/",
    component: _4a4e9d27,
    pathToRegexpOptions: {"strict":true},
    name: "strong-together___ar"
  }, {
    path: "/ar/terms/",
    component: _7e78a1fb,
    pathToRegexpOptions: {"strict":true},
    name: "terms___ar"
  }, {
    path: "/ar/volunteers/",
    component: _7eca8b28,
    pathToRegexpOptions: {"strict":true},
    name: "volunteers___ar"
  }, {
    path: "/en/branch-activities/",
    component: _2c17bc6f,
    pathToRegexpOptions: {"strict":true},
    name: "branch-activities___en"
  }, {
    path: "/en/branches/",
    component: _29c53b0f,
    pathToRegexpOptions: {"strict":true},
    name: "branches___en"
  }, {
    path: "/en/categories/",
    component: _0a6b475b,
    pathToRegexpOptions: {"strict":true},
    name: "categories___en"
  }, {
    path: "/en/clinical-researches/",
    component: _1e60f93a,
    pathToRegexpOptions: {"strict":true},
    name: "clinical-researches___en"
  }, {
    path: "/en/contact/",
    component: _56ee0897,
    pathToRegexpOptions: {"strict":true},
    name: "contact___en"
  }, {
    path: "/en/contribution/",
    component: _fcf9e962,
    pathToRegexpOptions: {"strict":true},
    name: "contribution___en"
  }, {
    path: "/en/drugs/",
    component: _0d9f66ec,
    pathToRegexpOptions: {"strict":true},
    name: "drugs___en"
  }, {
    path: "/en/events/",
    component: _1d1787d0,
    pathToRegexpOptions: {"strict":true},
    name: "events___en"
  }, {
    path: "/en/forum/",
    component: _2a1f8b58,
    pathToRegexpOptions: {"strict":true},
    name: "forum___en"
  }, {
    path: "/en/newsflashes/",
    component: _3266b1bc,
    pathToRegexpOptions: {"strict":true},
    name: "newsflashes___en"
  }, {
    path: "/en/privacy-policy/",
    component: _1c4e8f5a,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___en"
  }, {
    path: "/en/rights-and-services/",
    component: _5db481c1,
    pathToRegexpOptions: {"strict":true},
    name: "rights-and-services___en"
  }, {
    path: "/en/search/",
    component: _cc5ccc32,
    pathToRegexpOptions: {"strict":true},
    name: "search___en"
  }, {
    path: "/en/speaker-messages/",
    component: _154757ce,
    pathToRegexpOptions: {"strict":true},
    name: "speaker-messages___en"
  }, {
    path: "/en/strong-together/",
    component: _4a4e9d27,
    pathToRegexpOptions: {"strict":true},
    name: "strong-together___en"
  }, {
    path: "/en/terms/",
    component: _7e78a1fb,
    pathToRegexpOptions: {"strict":true},
    name: "terms___en"
  }, {
    path: "/en/volunteers/",
    component: _7eca8b28,
    pathToRegexpOptions: {"strict":true},
    name: "volunteers___en"
  }, {
    path: "/ru/branch-activities/",
    component: _2c17bc6f,
    pathToRegexpOptions: {"strict":true},
    name: "branch-activities___ru"
  }, {
    path: "/ru/branches/",
    component: _29c53b0f,
    pathToRegexpOptions: {"strict":true},
    name: "branches___ru"
  }, {
    path: "/ru/categories/",
    component: _0a6b475b,
    pathToRegexpOptions: {"strict":true},
    name: "categories___ru"
  }, {
    path: "/ru/clinical-researches/",
    component: _1e60f93a,
    pathToRegexpOptions: {"strict":true},
    name: "clinical-researches___ru"
  }, {
    path: "/ru/contact/",
    component: _56ee0897,
    pathToRegexpOptions: {"strict":true},
    name: "contact___ru"
  }, {
    path: "/ru/contribution/",
    component: _fcf9e962,
    pathToRegexpOptions: {"strict":true},
    name: "contribution___ru"
  }, {
    path: "/ru/drugs/",
    component: _0d9f66ec,
    pathToRegexpOptions: {"strict":true},
    name: "drugs___ru"
  }, {
    path: "/ru/events/",
    component: _1d1787d0,
    pathToRegexpOptions: {"strict":true},
    name: "events___ru"
  }, {
    path: "/ru/forum/",
    component: _2a1f8b58,
    pathToRegexpOptions: {"strict":true},
    name: "forum___ru"
  }, {
    path: "/ru/newsflashes/",
    component: _3266b1bc,
    pathToRegexpOptions: {"strict":true},
    name: "newsflashes___ru"
  }, {
    path: "/ru/privacy-policy/",
    component: _1c4e8f5a,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___ru"
  }, {
    path: "/ru/rights-and-services/",
    component: _5db481c1,
    pathToRegexpOptions: {"strict":true},
    name: "rights-and-services___ru"
  }, {
    path: "/ru/search/",
    component: _cc5ccc32,
    pathToRegexpOptions: {"strict":true},
    name: "search___ru"
  }, {
    path: "/ru/speaker-messages/",
    component: _154757ce,
    pathToRegexpOptions: {"strict":true},
    name: "speaker-messages___ru"
  }, {
    path: "/ru/strong-together/",
    component: _4a4e9d27,
    pathToRegexpOptions: {"strict":true},
    name: "strong-together___ru"
  }, {
    path: "/ru/terms/",
    component: _7e78a1fb,
    pathToRegexpOptions: {"strict":true},
    name: "terms___ru"
  }, {
    path: "/ru/volunteers/",
    component: _7eca8b28,
    pathToRegexpOptions: {"strict":true},
    name: "volunteers___ru"
  }, {
    path: "/strong-together/contact/",
    component: _28325e10,
    pathToRegexpOptions: {"strict":true},
    name: "strong-together-contact___he"
  }, {
    path: "/volunteers/contact/",
    component: _ae4da40e,
    pathToRegexpOptions: {"strict":true},
    name: "volunteers-contact___he"
  }, {
    path: "/volunteers/thank-you/",
    component: _55e02bd6,
    pathToRegexpOptions: {"strict":true},
    name: "volunteers-thank-you___he"
  }, {
    path: "/ar/strong-together/contact/",
    component: _28325e10,
    pathToRegexpOptions: {"strict":true},
    name: "strong-together-contact___ar"
  }, {
    path: "/ar/volunteers/contact/",
    component: _ae4da40e,
    pathToRegexpOptions: {"strict":true},
    name: "volunteers-contact___ar"
  }, {
    path: "/ar/volunteers/thank-you/",
    component: _55e02bd6,
    pathToRegexpOptions: {"strict":true},
    name: "volunteers-thank-you___ar"
  }, {
    path: "/en/strong-together/contact/",
    component: _28325e10,
    pathToRegexpOptions: {"strict":true},
    name: "strong-together-contact___en"
  }, {
    path: "/en/volunteers/contact/",
    component: _ae4da40e,
    pathToRegexpOptions: {"strict":true},
    name: "volunteers-contact___en"
  }, {
    path: "/en/volunteers/thank-you/",
    component: _55e02bd6,
    pathToRegexpOptions: {"strict":true},
    name: "volunteers-thank-you___en"
  }, {
    path: "/ru/strong-together/contact/",
    component: _28325e10,
    pathToRegexpOptions: {"strict":true},
    name: "strong-together-contact___ru"
  }, {
    path: "/ru/volunteers/contact/",
    component: _ae4da40e,
    pathToRegexpOptions: {"strict":true},
    name: "volunteers-contact___ru"
  }, {
    path: "/ru/volunteers/thank-you/",
    component: _55e02bd6,
    pathToRegexpOptions: {"strict":true},
    name: "volunteers-thank-you___ru"
  }, {
    path: "/ar/clinical-researches/categories/:slug/",
    component: _05841362,
    pathToRegexpOptions: {"strict":true},
    name: "clinical-researches-categories-slug___ar"
  }, {
    path: "/en/clinical-researches/categories/:slug/",
    component: _05841362,
    pathToRegexpOptions: {"strict":true},
    name: "clinical-researches-categories-slug___en"
  }, {
    path: "/ru/clinical-researches/categories/:slug/",
    component: _05841362,
    pathToRegexpOptions: {"strict":true},
    name: "clinical-researches-categories-slug___ru"
  }, {
    path: "/ar/branches/regions/:slug?/:branchSlug?/",
    component: _c8157198,
    pathToRegexpOptions: {"strict":true},
    name: "branches-regions-slug-branchSlug___ar"
  }, {
    path: "/en/branches/regions/:slug?/:branchSlug?/",
    component: _c8157198,
    pathToRegexpOptions: {"strict":true},
    name: "branches-regions-slug-branchSlug___en"
  }, {
    path: "/ru/branches/regions/:slug?/:branchSlug?/",
    component: _c8157198,
    pathToRegexpOptions: {"strict":true},
    name: "branches-regions-slug-branchSlug___ru"
  }, {
    path: "/ar/articles/:slug?/",
    component: _1a1335f4,
    pathToRegexpOptions: {"strict":true},
    name: "articles-slug___ar"
  }, {
    path: "/ar/categories/:slug/",
    component: _05f9db54,
    pathToRegexpOptions: {"strict":true},
    name: "categories-slug___ar"
  }, {
    path: "/ar/drugs/:slug/",
    component: _110ca97c,
    pathToRegexpOptions: {"strict":true},
    name: "drugs-slug___ar"
  }, {
    path: "/ar/forum/:slug/",
    component: _0ec1a493,
    pathToRegexpOptions: {"strict":true},
    name: "forum-slug___ar"
  }, {
    path: "/ar/newsletter/:id?/",
    component: _77d793c0,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter-id___ar"
  }, {
    path: "/ar/subcategories/:slug?/",
    component: _65d959ce,
    pathToRegexpOptions: {"strict":true},
    name: "subcategories-slug___ar"
  }, {
    path: "/clinical-researches/categories/:slug/",
    component: _05841362,
    pathToRegexpOptions: {"strict":true},
    name: "clinical-researches-categories-slug___he"
  }, {
    path: "/en/articles/:slug?/",
    component: _1a1335f4,
    pathToRegexpOptions: {"strict":true},
    name: "articles-slug___en"
  }, {
    path: "/en/categories/:slug/",
    component: _05f9db54,
    pathToRegexpOptions: {"strict":true},
    name: "categories-slug___en"
  }, {
    path: "/en/drugs/:slug/",
    component: _110ca97c,
    pathToRegexpOptions: {"strict":true},
    name: "drugs-slug___en"
  }, {
    path: "/en/forum/:slug/",
    component: _0ec1a493,
    pathToRegexpOptions: {"strict":true},
    name: "forum-slug___en"
  }, {
    path: "/en/newsletter/:id?/",
    component: _77d793c0,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter-id___en"
  }, {
    path: "/en/subcategories/:slug?/",
    component: _65d959ce,
    pathToRegexpOptions: {"strict":true},
    name: "subcategories-slug___en"
  }, {
    path: "/ru/articles/:slug?/",
    component: _1a1335f4,
    pathToRegexpOptions: {"strict":true},
    name: "articles-slug___ru"
  }, {
    path: "/ru/categories/:slug/",
    component: _05f9db54,
    pathToRegexpOptions: {"strict":true},
    name: "categories-slug___ru"
  }, {
    path: "/ru/drugs/:slug/",
    component: _110ca97c,
    pathToRegexpOptions: {"strict":true},
    name: "drugs-slug___ru"
  }, {
    path: "/ru/forum/:slug/",
    component: _0ec1a493,
    pathToRegexpOptions: {"strict":true},
    name: "forum-slug___ru"
  }, {
    path: "/ru/newsletter/:id?/",
    component: _77d793c0,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter-id___ru"
  }, {
    path: "/ru/subcategories/:slug?/",
    component: _65d959ce,
    pathToRegexpOptions: {"strict":true},
    name: "subcategories-slug___ru"
  }, {
    path: "/branches/regions/:slug?/:branchSlug?/",
    component: _c8157198,
    pathToRegexpOptions: {"strict":true},
    name: "branches-regions-slug-branchSlug___he"
  }, {
    path: "/articles/:slug?/",
    component: _1a1335f4,
    pathToRegexpOptions: {"strict":true},
    name: "articles-slug___he"
  }, {
    path: "/categories/:slug/",
    component: _05f9db54,
    pathToRegexpOptions: {"strict":true},
    name: "categories-slug___he"
  }, {
    path: "/drugs/:slug/",
    component: _110ca97c,
    pathToRegexpOptions: {"strict":true},
    name: "drugs-slug___he"
  }, {
    path: "/forum/:slug/",
    component: _0ec1a493,
    pathToRegexpOptions: {"strict":true},
    name: "forum-slug___he"
  }, {
    path: "/newsletter/:id?/",
    component: _77d793c0,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter-id___he"
  }, {
    path: "/subcategories/:slug?/",
    component: _65d959ce,
    pathToRegexpOptions: {"strict":true},
    name: "subcategories-slug___he"
  }, {
    path: "/",
    component: _570a3874,
    pathToRegexpOptions: {"strict":true},
    name: "index___he"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
